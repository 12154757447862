import CartTotals from '../../components/organisms/Cart/CartTotals'
import {
  GET_PRODUCTS,
  SEARCH_PRODUCTS,
  GET_PRODUCT_BY_ID,
  ADD_TO_CART,
  ADD_TO_COMP,
  UPDATE_CART_PRODUCT,
  REMOVE_CART,
  DEL_COMPARE,
  CLEAR_CART,
  ADD_TO_FAV,
  REMOVE_FAV,
  GET_CARTS,
  GET_CART,
  GET_CART_SUMMARY,
  GET_CART_TOTALS,
  REMOVE_CART_TOTALS_PLACEHOLDERS,
  GET_CART_SHIPPING_ADDRESS,
  GET_SHIPPING_QUOTES,
  GET_SHIPPING_QUOTE,
  GET_EXISTING_QUOTE,
  UPDATE_CART_PRODUCT_DETAILS,
  SET_CART_SHIPPING_ADDRESS_MODAL,
  GET_COUPON_CODE,
  GET_SAVED_CARTS_WITH_ITEMS,
  SET_CHECKOUT_STEPS_STATUS,
  CLEAR_CHECKOUT_STEPS_STATUS,
  CLEAR_CHECKOUT_STEPS_STATUS_EXCEPT_ONE,
  INITIALIZE_PRODUCT_STATE,
  AUTH_CREDIT_CARD,
  GET_CART_BILLING,
  SET_CC_PATH,
  SUBMIT_ORDER,
  SET_CREDIT_CARD_PAYMENT,
  UPDATE_PRODUCT_IN_CART,
  PURGE_OLD_CART_DATA,
  CLEAR_SHIPPING_QUOTES,
  CLEAR_SUBMIT_ORDER_RESPONSE,
  SET_CART_UPDATING,
  SET_CART_HAS_CARRIER_QUOTES,
  SET_CART_HAS_VALID_CARRIER,
  SET_PAGE,
  SET_PAGINATION,
  GET_CART_RECOMMENDED_PRODUCTS,
} from './constants'
import { initialState } from './ProductProvider'
import { IProductAction, IProductState } from './types'

export default function productReducer(
  state: IProductState = initialState,
  action: IProductAction,
) {
  const payload = action.payload

  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload,
      }

    case SEARCH_PRODUCTS:
      return {
        ...state,
        // products: payload, // todo: clean up. if this is really going to replace the shoplist products, then it needs some thought.  Probably just needs to be a separated var other than products.
      }

    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        product: payload,
      }

    case ADD_TO_CART:
      const carts = payload ? [...state.carts, payload] : state.carts
      return {
        ...state,
        carts: carts,
      }

    case ADD_TO_COMP:
      const compare = payload ? [...state.compare, payload] : state.compare
      return {
        ...state,
        compare: compare,
      }

    case UPDATE_CART_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.products_id === payload.data.products_id
            ? {
                ...product,
                id: payload.data.id,
                quantity: payload.data.quantity,
                total_price: payload.data.quantity * product.final_price,
                success: payload.success,
                status: payload.status,
                message: payload.message,
              }
            : product,
        ),
      }

    case CLEAR_SHIPPING_QUOTES:
      return {
        ...state,
        shippingQuotes: [],
        cartHasCarriersQuotes: false,
        shippingQuotesLoaded: false,
      }

    case SET_CART_HAS_CARRIER_QUOTES:
      return {
        ...state,
        cartHasCarriersQuotes: payload,
      }

    case SET_CART_HAS_VALID_CARRIER:
      return {
        ...state,
        cart: {
          ...state.cart,
          has_valid_carrier: payload ? state.cart?.has_valid_carrier : false,
        },
      }

    case UPDATE_PRODUCT_IN_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          items: state.cart?.items?.map((product) =>
            product.products_id === payload.data.products_id
              ? {
                  ...product,
                  id: payload.data.id,
                  quantity: payload.data.quantity,
                  total_price: payload.data.quantity * product.final_price,
                  success: payload.success,
                  status: payload.status,
                  message: payload.message,
                }
              : product,
          ),
        },
      }

    case UPDATE_CART_PRODUCT_DETAILS:
      return {
        ...state,
        product: {
          ...state.product,
          id: payload.data.id,
          quantity: payload.data.quantity,
          success: payload.success,
          status: payload.status,
          message: payload.message,
        },
      }

    case GET_CARTS:
      return {
        ...state,
        carts: payload,
      }

    case GET_CART:
      return {
        ...state,
        cart: payload,
      }

    case GET_CART_SUMMARY:
      return {
        ...state,
        cartSummary: payload,
      }

    case GET_CART_TOTALS:
      return {
        ...state,
        cartTotals: payload.totals,
        cartOptionFees: payload.option_fees,
        cartTotal: payload.total,
        cartSubtotal: payload.subtotal
      }

    case REMOVE_CART_TOTALS_PLACEHOLDERS:
      return {
        ...state,
        cartTotals: state.cartTotals
          ? state.cartTotals.filter(
              (cartTotal) =>
                cartTotal.text !== 'Calculated at checkout' ||
                cartTotal.class === 'ot_shipping',
            )
          : [],
      }
    case SET_CART_SHIPPING_ADDRESS_MODAL:
      return {
        ...state,
        cartShippingAddressModal: payload,
      }

    case GET_CART_SHIPPING_ADDRESS:
      return {
        ...state,
        cartShippingAddress: payload.cart_shipping_address,
        cartShippingAddressIsValid: payload.cart_shipping_address_is_valid,
        cartShippingAddressErrorMessage:
          payload.cart_shipping_address_error_message,
      }

    case GET_SHIPPING_QUOTES:
      return {
        ...state,
        shippingQuotes: payload,
        shippingQuotesLoaded: true,
      }

    case GET_SHIPPING_QUOTE:
      return {
        ...state,
        shippingQuotes: state.shippingQuotes.map((shippingQuote) =>
          shippingQuote.optionId === payload.optionId &&
          payload.shipping_quotes_id !== null &&
          payload.shipping_quotes_id !== undefined
            ? payload
            : shippingQuote,
        ),
      }

    case GET_EXISTING_QUOTE:
      return {
        ...state,
        shippingQuote: payload,
      }

    case GET_COUPON_CODE:
      return {
        ...state,
        couponCode: payload,
      }

    case SET_CREDIT_CARD_PAYMENT:
      return {
        ...state,
        creditCardPayment: payload,
      }
    case AUTH_CREDIT_CARD:
      return {
        ...state,
        creditCardResponse: payload,
      }

    case GET_CART_BILLING:
      return {
        ...state,
        cartBilling: payload,
      }
    case SET_CC_PATH:
      return {
        ...state,
        ccPath: payload,
      }

    case SUBMIT_ORDER:
      return {
        ...state,
        submitOrderResponse: payload,
      }

    case CLEAR_SUBMIT_ORDER_RESPONSE:
      return {
        ...state,
        submitOrderResponse: payload,
      }

    case REMOVE_CART:
      return {
        ...state,
        carts: state.carts.filter((item) => item.id !== parseInt(payload)),
      }

    case DEL_COMPARE:
      return {
        ...state,
        compare: state.compare.filter((item) => item.id !== parseInt(payload)),
      }

    case CLEAR_CART:
      return {
        ...state,
        carts: [],
      }

    case ADD_TO_FAV:
      const favorites = payload
        ? [...state.favorites, payload]
        : state.favorites
      return {
        ...state,
        favorites: favorites,
      }

    case REMOVE_FAV:
      return {
        ...state,
        carts: state.favorites.filter((item) => item.id !== parseInt(payload)),
      }

    case GET_SAVED_CARTS_WITH_ITEMS:
      return {
        ...state,
        savedCartsWithItems: payload,
      }

    case SET_CHECKOUT_STEPS_STATUS:
      return {
        ...state,
        checkoutStepsStatus: {
          ...state.checkoutStepsStatus,
          [payload]: true,
        },
      }
    case CLEAR_CHECKOUT_STEPS_STATUS:
      return {
        ...state,
        checkoutStepsStatus: {},
      }
    case CLEAR_CHECKOUT_STEPS_STATUS_EXCEPT_ONE:
      return {
        ...state,
        checkoutStepsStatus: {
          [payload]: true,
        },
      }

    case INITIALIZE_PRODUCT_STATE:
      return {
        ...initialState,
      }

    case PURGE_OLD_CART_DATA:
      return {
        ...state,
        oldCartDataPurged: payload,
      }

    case SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload,
        },
      }

    case SET_PAGINATION:
      return {
        ...state,
        pagination: payload,
      }

    case SET_CART_UPDATING:
      return {
        ...state,
        cartUpdating: payload,
      }

    case GET_CART_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        cartRecommendedProducts: payload,
      }

    default:
      return state
  }
}
