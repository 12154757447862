export const bannerKeys = {
  all: ['banners'] as const,
  loggedIn: () => [...bannerKeys.all, 'loggedIn'] as const,
  loggedOut: () => [...bannerKeys.all, 'loggedOut'] as const,
  prolineAboutPage: () => [...bannerKeys.all, 'prolineAboutPage'] as const,
}

export const featuredTilesKeys = {
  all: ['featuredTiles'] as const,
  featuredTiles: () => [...featuredTilesKeys.all] as const,
}

export const landingPageSavedCartsKeys = {
  all: ['landingPageSavedCarts'] as const,
  savedCarts: (limit?: number) =>
    [...landingPageSavedCartsKeys.all, limit] as const,
}

export const landingPageOpenOrdersKeys = {
  all: ['landingPageOpenOrders'] as const,
  openOrders: (limit?: number, statuses?: Array<number>) =>
    [...landingPageOpenOrdersKeys.all, limit, statuses] as const,
}

export const activeRedirectsKeys = {
  all: ['activeRedirects'] as const,
  activeRedirects: () => [...activeRedirectsKeys.all] as const,
}

export const socialMediaKeys = {
  all: ['socialMediaKeys'] as const,
  socialMedia: () => [...socialMediaKeys.all] as const,
}
